<template>
    <section
        class="PlainTextBlock"
        :class="[
            'PlainTextBlock--align-' + alignment,
            {
                'PlainTextBlock--in-view': inView,
            },
        ]"
    >
        <div ref="sectionContent">
            <h2 v-if="transformedTitle" class="PlainTextBlock__title">
                <span v-html="transformedTitle" />
            </h2>
            <PrismicRichText
                :field="slice.primary.body"
                class="PlainTextBlock__body"
            />
        </div>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

const { $wordBuilder } = useNuxtApp()

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
    getSliceComponentProps<Content.PlainTextBlockSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const transformedTitle = ref('')
const sectionContentObserver = ref()
const inView = ref(false)
const sectionContent = ref()

if (props.slice.primary.title) {
    $wordBuilder(props.slice.primary.title).then((res) => {
        transformedTitle.value = res.words
    })
}

const alignment = computed(() => {
    return props.slice.primary?.alignment?.toLowerCase()
})

onMounted(() => {
    sectionContentObserver.value = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    inView.value = true
                }
            })
        },
    )

    sectionContentObserver.value.observe(sectionContent.value)
})
</script>

<style lang="scss">
.theme--white {
    .PlainTextBlock {
        color: var(--black);
    }

    .PlainTextBlock__body {
        a {
            color: var(--black);
        }
    }
}

.PlainTextBlock {
    grid-column: 2 / span 22;
    display: flex;
    color: var(--white);
    margin-top: 8.75rem;

    @include medium-up {
        margin-top: 11.25rem;
    }

    .PlainTextBlock__title {
        @include large-up {
            max-width: clamp(37.5rem, 60vw, 45rem);
        }
    }

    .PlainTextBlock__body {
        @include large-up {
            max-width: clamp(37.5rem, 60vw, 45rem);
        }
    }
}

.PlainTextBlock--align-right {
    justify-content: flex-end;
}

.PlainTextBlock--align-center {
    justify-content: center;
    text-align: center;
}

.PlainTextBlock--in-view {
    .PlainTextBlock__title {
        .word span {
            animation: text-reveal 0.8s forwards cubic-bezier(0.25, 1, 0.5, 1)
                var(--transition-delay);
        }
    }

    .PlainTextBlock__body {
        transform: translateY(0);
        opacity: 1;
    }
}

.PlainTextBlock__title {
    @include heading-2;

    margin-bottom: 1.125rem;

    .word {
        overflow: hidden;
        display: inline-flex;
        line-height: 1;

        span {
            display: block;
            opacity: 1;
            transform: translate3d(0, 100%, 0);
            padding-right: 0.05em; // offset italic font edging out on the right
            --transition-delay: calc(0.25s + var(--index) * 0.05s);
        }
    }
}

.PlainTextBlock__body {
    transform: translateY(32px);
    opacity: 0;
    transition: all 0.5s 0.2s ease;

    p {
        @include body-lg;

        letter-spacing: -0.4px;
        margin-bottom: 1.5rem;
    }

    ul {
        list-style: disc;
    }

    ul,
    ol {
        padding-left: 0;
        margin-bottom: 2rem;

        li {
            margin-bottom: 1rem;
            margin-left: 1rem;
        }
    }
}
</style>
